<template>
  <v-container
    class="payment-completed justify-center d-flex ma-4"
    v-if="loading"
  >
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-container>

  <v-container class="payment-completed" v-else>
    <div class="checkout-title">
      <CategoryTitle
        :category="category"
        :showOnMobile="true"
        :centered="$vuetify.breakpoint.xs"
        :addContainer="false"
      />
    </div>
    <v-row>
      <v-col cols="12" md="8">
        <v-card class="h-100" color="transparent" elevation="0">
          <v-card-title v-if="order && paymentFailed">
            {{ $t("paymentCompleted.failed") }}
          </v-card-title>
          <v-card-text v-if="order && paymentFailed" class="px-0 mt-3 mb-6">
            <router-link
              :aria-label="order.orderId"
              :title="order.orderId"
              class="order-id d-block default--text pa-4 mb-4 "
              :to="{ name: 'Order', params: { orderId: order.orderId } }"
            >
              N. {{ order.orderId }}
            </router-link>

            <p
              class="payment-failed-desc"
              v-html="$t('paymentCompleted.failedDesc')"
            />

            <p
              class="repay"
              v-if="order.isPayable"
              v-html="$t('order.repay', [order.orderId])"
            ></p>
          </v-card-text>

          <template v-if="order && !paymentFailed">
            <v-card-title>
              <span
                class="text-uppercase payment-completed-success-title"
                v-html="$t('paymentCompleted.success')"
              ></span>
            </v-card-title>
            <v-card-text>
              <div class="px-0">
                <!-- PICKUP -->
                <template
                  v-if="!order.isPayable && !order.homeDelivery && !easyDrive"
                >
                  <p
                    class="note"
                    v-html="$t('paymentCompleted.detail.pickup.note')"
                  />
                  <router-link
                    :aria-label="order.orderId"
                    :title="order.orderId"
                    class="order-id d-block default--text pa-4 mb-8 mt-6"
                    :to="{
                      name: 'Order',
                      params: { orderId: order.orderId }
                    }"
                  >
                    N. {{ order.orderId }}
                  </router-link>
                  <i18n
                    path="paymentCompleted.detail.pickup.reminder"
                    tag="p"
                    class="reminder"
                  >
                    <template v-slot:ordersLink>
                      <router-link
                        :aria-label="$t('paymentCompleted.detail.pickup.note')"
                        :title="$t('paymentCompleted.detail.pickup.note')"
                        class="text-decoration-none"
                        :to="{ name: 'Orders' }"
                      >
                        {{
                          $t("paymentCompleted.detail.pickup.reminderOrderLink")
                        }}
                      </router-link>
                    </template>
                  </i18n>
                  <p
                    class="where"
                    v-html="
                      $t('paymentCompleted.detail.pickup.where', [
                        order.shippingAddress.addressName
                      ])
                    "
                  />
                </template>

                <!-- HOME -->
                <div v-if="!order.isPayable && order.homeDelivery">
                  <p
                    class="note"
                    v-html="$t('paymentCompleted.detail.home.note')"
                  />
                  <router-link
                    :aria-label="order.orderId"
                    :title="order.orderId"
                    class="order-id d-block default--text pa-4 mb-4 "
                    :to="{
                      name: 'Order',
                      params: { orderId: order.orderId }
                    }"
                  >
                    {{ order.orderId }}
                  </router-link>
                  <i18n
                    path="paymentCompleted.detail.pickup.reminder"
                    tag="p"
                    class="reminder"
                  >
                    <template v-slot:ordersLink>
                      <router-link
                        :aria-label="$t('paymentCompleted.detail.pickup.note')"
                        :title="$t('paymentCompleted.detail.pickup.note')"
                        class="text-decoration-none"
                        :to="{ name: 'Orders' }"
                      >
                        {{
                          $t("paymentCompleted.detail.pickup.reminderOrderLink")
                        }}
                      </router-link>
                    </template>
                  </i18n>
                  <p
                    class="where"
                    v-html="
                      $t('paymentCompleted.detail.home.where', [
                        order.shippingAddress.address1,
                        order.shippingAddress.city
                      ])
                    "
                  />
                </div>
              </div>

              <v-divider class="my-5" />
              <i18n
                path="paymentCompleted.pending"
                tag="p"
                class="pending font-weight-semibold"
                v-if="paymentPending"
              >
                <template v-slot:link>
                  <router-link
                    :aria-label="order.orderId"
                    :title="order.orderId"
                    class="text-decoration-none"
                    :to="{
                      name: 'Order',
                      params: { orderId: order.orderId }
                    }"
                  >
                    {{ $t("paymentCompleted.ordersLink") }}
                  </router-link>
                </template>
              </i18n>
              <p
                class="info-2 font-weight-semibold"
                v-if="order.orderStatusId == 1"
                v-html="$t('order.info2', [order.orderId])"
              ></p>
              <p
                class="repay"
                v-if="order.isPayable"
                v-html="$t('order.repay', [order.orderId])"
              ></p>
              <p
                class="info-3 font-weight-semibold"
                v-if="order.orderStatusId == 11"
                v-html="$t('order.info3', [order.orderId])"
              ></p>
            </v-card-text>
          </template>

          <v-card-title v-if="!order && !loading">
            <span
              class="text-h3"
              v-html="$t('paymentCompleted.cantLoadOrder')"
            ></span>
          </v-card-title>

          <v-card-actions
            class="flex-column flex-sm-row justify-space-between gap-2"
          >
            <v-btn
              :to="{ name: 'Home', path: '/' }"
              large
              text
              color="black"
              :block="$vuetify.breakpoint.xs"
              class="font-weight-semibold"
              ><v-icon size="20" color="primary" class="mr-2">$arrowLeft</v-icon
              >{{ $t("paymentCompleted.goToHome") }}</v-btn
            >
            <template v-if="paymentFailed && order.isPayable">
              <v-btn
                v-if="!showPayment"
                large
                min-width="200"
                class="mx-0"
                depressed
                color="primary"
                :block="$vuetify.breakpoint.xs"
                @click="toggleShowPayment"
              >
                {{ $t("paymentCompleted.retry") }}
              </v-btn>
            </template>
            <v-btn
              v-if="!order && !loading"
              color="primary"
              depressed
              :to="{ name: 'Orders' }"
              >{{ $t("paymentCompleted.goToOrderListBtn") }}</v-btn
            >
          </v-card-actions>

          <div
            class="payment-type-list-container white pa-4 lighten-1 mt-5 rounded-md"
            v-if="showPayment"
          >
            <PaymentTypeList
              class="ptl-wrapper"
              mode="order"
              :order-id="order.orderId"
              :options="paymentTypeListOptions"
            />
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="4"
        ><category-block
          :target="category"
          position="position3"
          class="category-block category-block-17"
          :container="false"
      /></v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.payment-completed {
  .payment-completed-success-title {
    font-size: 20px;
  }
  .payment-type-list-container {
    border: 1px -var(grey-lighten1);
    .v-expansion-panel-header {
      .payment-method-header-label {
        @media #{map-get($display-breakpoints, 'xs-only')} {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .mobile.images-payment-type {
          display: none;
        }
      }
    }
  }
  .ptl-wrapper {
    :deep(.primary--text) {
      color: #464646 !important;
    }
    :deep(.payment-title) {
      font-size: 18px !important;
      font-weight: 600 !important;
    }
    :deep(.prebilled-amount) {
      font-weight: normal;
      line-height: 1.2;
      font-size: 12px !important;
      .v-alert__content div {
        font-size: 12px !important;
      }
    }
    :deep(.payment-type) {
      .payment-back-btn {
        border: 1px solid var(--v-primary-base);
        color: var(--v-primary-base) !important;
      }
      .v-btn {
        border-radius: 9999px !important;
      }
    }
    :deep(.add-new-card-btn) {
      box-shadow: none;
      margin-right: 12px;
      height: 30px !important;
      min-width: 190px !important;
      padding: 0 16px;
    }
    :deep(.prebilled-amount) {
      margin-bottom: 0px !important;
    }
    :deep(.actions-btn-container) {
      display: flex !important;
      // justify-content: space-between !important;
      padding: 0 8px !important;
    }
    :deep(.go-back-btn) {
      height: 44px !important;
      min-width: 78px !important;
      padding: 0 19.5555555556px !important;
      color: var(--v-primary-base) !important;
      border: 1px solid var(--v-primary-base) !important;
    }
    :deep(.gateway-btn) {
      height: 44px !important;
      min-width: 78px !important;
      padding: 0 19.5555555556px !important;
      color: #ffffff !important;
    }

    :deep(.add-card-container) {
      flex-direction: column !important;
      align-items: flex-start !important;
    }

    :deep(.remember-card-checkbox) {
      margin-bottom: 12px;
      .v-label {
        font-size: 13px !important;
        font-weight: 400 !important;
      }
      .v-icon {
        font-size: 20px !important;
      }
    }
  }
  .payment-methods {
    border-radius: 8px !important;
    :deep(p) {
      margin-bottom: 0px;
    }
    :deep(.text-h3) {
      font-weight: 600;
      font-size: 24px !important;
      text-transform: uppercase;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      :deep(.text-h3) {
        font-size: 18px !important;
      }
    }
    :deep(.v-expansion-panel) {
      margin: 8px 0 !important;
      padding: 0 8px !important;
      border: 2px solid var(--v-grey-lighten1);
      padding: 0;
      border-radius: $border-radius-root;
      margin-bottom: 0px;
      &.v-expansion-panel--active {
        border-radius: $border-radius-root;
      }
      &:last-child {
        margin-bottom: 0px;
        // border-top: none;
      }
      &::before {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
      }
      .v-expansion-panel-header {
        border-radius: $border-radius-root !important;
        padding-left: 0px;
        label {
          display: flex;
          align-items: center;
          // padding-left: 20px;
        }
        .payment-method-img {
          height: 50px !important;
        }
      }
      .v-expansion-panel-content__wrap {
        padding: 12px 24px !important;
        border-radius: $border-radius-root !important;

        .prebilled-amount {
          margin-bottom: 20px;
        }
        .caption {
          margin-top: 20px;
        }
      }

      .prebilledAmountInfo {
        margin-bottom: 20px;
        .text {
          color: var(--v-primary-lighten1);
        }
        .sub {
          font-size: 13px;
        }
      }
      label {
        text-transform: none;
        font-weight: 600;
      }
      label:before {
        // top: 15px;
        background: transparent;
      }
      label:after {
        // top: 20px;
      }
    }
    :deep(.v-expansion-panel::after) {
      display: none;
    }
  }
  .v-card {
    .v-card__title {
      font-size: 2rem;
      font-weight: 500;
    }
    .v-card__title,
    .v-card__text {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .order-id {
    background: white;
    font-size: 32px;
    font-weight: bold;
    color: var(--v-primary-base) !important;
    border: 1px solid var(--v-primary-base);
    border-radius: 6px;
    text-decoration: none;
    width: fit-content;
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import categoryMixins from "~/mixins/category";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "PaymentCompleted",
  mixins: [categoryMixins],
  components: {
    PaymentTypeList,
    CategoryTitle
  },
  data() {
    return {
      loading: true,
      order: {},
      showPayment: false,
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        titleStyle: "primary--text text--darken-2 text-body-1 mb-2",
        mainBackgroundColor: "transparent",
        confirm: { color: "primary" },
        back: {
          outline: true,
          class: "primary--text"
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      isOrderSentToAnalytics: "app/isOrderSentToAnalytics"
    }),
    paymentFailed() {
      return this.order?.orderStatusId == 11 || this.order?.orderStatusId == 1;
    },
    paymentPending() {
      return this.order?.orderStatusId == 2;
    },
    detailDate() {
      return this.$dayjs(this.order.timeslot.date).format("DD MMMM");
    },
    detailTimeslot() {
      let from = this.$dayjs(this.order.timeslot.beginTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm");
      let to = this.$dayjs(this.order.timeslot.endTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm");
      if (!this.order.homeDelivery) {
        return `potrai ritirarlo dalle <b>${from}</b> alle
          <b>${to}</b>`;
      }
      return `ti verrà consegnato dalle <b>${from}</b> alle
          <b>${to}</b>`;
    },
    easyDrive() {
      return (
        this.order.warehouse.params != undefined &&
        this.order.warehouse.params.EASYDRIVE === "1"
      );
    },
    shippingAddress() {
      return `${this.order.shippingAddress.addressName} (${this.order.shippingAddress.address1}
            ${this.order.shippingAddress.addressNumber}, ${this.order.shippingAddress.city})`;
    }
  },
  methods: {
    ...mapActions({
      addOrderToAnalytics: "app/addOrderSentToAnalytics"
    }),
    toggleShowPayment() {
      this.showPayment = !this.showPayment;
    },
    async fetchOrder(orderId) {
      let _this = this;
      try {
        _this.order = await OrderService.getOrder(orderId);

        if (_this.order.orderStatusId == undefined) {
          _this.order = null;
        } else if (_this.order.orderStatusId == 3) {
          if (!this.isOrderSentToAnalytics(orderId)) {
            AnalyticsService.purchase(_this.order);
            this.addOrderToAnalytics(orderId);
          }
        }

        if (_this.order.orderStatusId >= 3) {
          _this.loading = false;
        }
      } catch (e) {
        console.log(e);
        _this.order = null;
        _this.loading = false;
      }
    }
  },
  async mounted() {
    let _this = this;
    await this.fetchOrder(this.$route.params.orderId);

    if (_this.order?.orderStatusId < 3) {
      setTimeout(async () => {
        await _this.fetchOrder(this.$route.params.orderId);
        _this.loading = false;
      }, 5000);
    }

    global.EventBus.$on("pay-response-ok", order => {
      this.showPayment = false;
      this.fetchOrder(order.orderId);
    });
  },
  beforeDestroy() {
    global.EventBus.$off("pay-response-ok");
  }
};
</script>
